import type { Article } from "../client";

export const getTopicTitleFromArticle = (article: Article, topicId: string) => {
  if (topicId === article?.story_vignette?.id) {
    return article.story_vignette.title;
  }

  if (topicId === article?.story_vignette?.supertag?.topic_id) {
    return article?.story_vignette?.supertag?.title;
  }

  return article?.tags?.find(topic => topic.topic_id === topicId)?.title;
};
