import React from "react";

import type { ReactNode } from "react";

import { Header } from "../components/Header/Header";
import { MainContainer } from "./MainContainer";
import { PremiumLockContainer } from "../containers/PremiumLockContainer";
import { ActiveArticleContainer } from "../containers/ActiveArticleContainer";
import { MainContainerWithSidebar } from "./MainContainerWithSidebar";
import { LayoutContext } from "../contexts/LayoutContext";
import { Messages } from "./Messages";
import { Ad } from "../components/Ad";
import { PremiumModal } from "../components/Modal/modals/PremiumModal/PremiumModal";
import { Modal } from "../components/Modal/Modal";
import { TrackingContainer } from "../containers/TrackingContainer";
import { OrvestoTrackerContainer } from "../containers/OrvestoTrackerContainer";
import { getLayoutName } from "../components/Ad/utils/ads/functions";

type ExtractContext<P> = P extends React.Context<infer ContextType>
  ? ContextType
  : never;
type layoutContextValueType = ExtractContext<typeof LayoutContext>;

export type LayoutType =
  | "latest"
  | "topics"
  | "bookmarks"
  | "categories"
  | "main";

type BaseLayoutProps = {
  children: ReactNode;
  withTopAd?: boolean;
  type?: LayoutType;
} & layoutContextValueType;

function BaseLayout({
  children,
  withTopAd = true,
  ...layoutContextValue
}: BaseLayoutProps) {
  const layout = getLayoutName();
  return (
    <LayoutContext.Provider value={layoutContextValue}>
      <Ad
        className="banner banner--takeover"
        id={`${layout === "desktop" ? "takeoverDesktop" : "takeoverMobile"}`}
        type="takeover"
        placement={`${
          layout === "desktop" ? "takeoverDesktop" : "takeoverMobile"
        }`}
      />
      <Header />
      <TrackingContainer>
        <OrvestoTrackerContainer>
          <PremiumLockContainer>
            <ActiveArticleContainer>
              <PremiumModal />
              <AppBodyContainer>
                <Modal />
                <Messages />
                <div className="container">
                  {withTopAd && (
                    <Ad
                      placement={`${
                        layout === "desktop"
                          ? "topPanorama"
                          : "mobileTopPanorama"
                      }`}
                      className="banner banner--toppanorama hidden-xs hidden-sm"
                      id={`${
                        layout === "desktop"
                          ? "topPanorama"
                          : "mobileTopPanorama"
                      }`}
                    />
                  )}
                  {children}
                </div>
              </AppBodyContainer>
            </ActiveArticleContainer>
          </PremiumLockContainer>
        </OrvestoTrackerContainer>
      </TrackingContainer>
    </LayoutContext.Provider>
  );
}

export function Layout(props: BaseLayoutProps) {
  return (
    <BaseLayout {...props}>
      <MainContainerWithSidebar type={props?.type}>
        {props.children}
      </MainContainerWithSidebar>
    </BaseLayout>
  );
}

export function LayoutWithoutSidebar(props: BaseLayoutProps) {
  return (
    <BaseLayout {...props}>
      <MainContainer>{props.children}</MainContainer>
    </BaseLayout>
  );
}

export function LayoutWithSidebarInTail({
  tail,
  ...props
}: { tail: ReactNode } & BaseLayoutProps) {
  return (
    <BaseLayout {...props}>
      <MainContainer>{props.children}</MainContainer>
      <MainContainerWithSidebar>{tail}</MainContainerWithSidebar>
    </BaseLayout>
  );
}

function AppBodyContainer({ children }: { children: ReactNode }) {
  return <div className="global-width app-bg">{children}</div>;
}
