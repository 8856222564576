import React from "react";

import type { ReactNode } from "react";
import type { LayoutType } from "./Layout";

import { MainContainer } from "./MainContainer";
import { SideBarSubscriptionsContainer } from "../containers/SideBarContainer/SideBarSubscriptionsContainer";
import { Ad } from "../components/Ad";
import { SideBarSubscriptions } from "../components/SideBar/SideBarSubscriptions";
import { SideBarTopicsContainer } from "../containers/SideBarContainer/SideBarTopicsContainer";
import { SideBarTopics } from "../components/SideBar/SideBarTopics";

export function MainContainerWithSidebar({
  children,
  type
}: {
  children: ReactNode;
  type?: LayoutType;
}) {
  return (
    <MainContainer>
      <div className="column column--feed main-anticolumn">
        <div
          className={`main-wide--feed main-wide--feed${
            type ? `--${type}` : ""
          }`}
        >
          {children}
        </div>
        <div className="main-narrow main-narrow--side shadow-ambient hidden-xs hidden-sm">
          <SideBarSubscriptionsContainer limit={3}>
            {groups => <SideBarSubscriptions feed={groups} />}
          </SideBarSubscriptionsContainer>
          <Ad
            id="side1"
            placement="side1"
            className="banner banner--side hidden-xs hidden-sm"
          />
          <SideBarTopicsContainer limit={5} sort="current">
            {(topics, getMore) => (
              <SideBarTopics
                header="Aktuella ämnen"
                topics={topics}
                getMore={getMore}
              />
            )}
          </SideBarTopicsContainer>
          <Ad
            id="side2"
            placement="side2"
            className="banner banner--side hidden-xs hidden-sm"
          />
          <SideBarTopicsContainer limit={5} sort="popular">
            {(topics, getMore) => (
              <SideBarTopics
                header="Populära ämnen"
                topics={topics}
                getMore={getMore}
              />
            )}
          </SideBarTopicsContainer>
        </div>
      </div>
    </MainContainer>
  );
}
