import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type ModalWrapperProps = {
  children: React.ReactNode;
};

type UseModal = () => {
  open: (event: React.UIEvent) => void;
  close: (event: React.UIEvent) => void;
  ModalWrapper: React.FC<ModalWrapperProps>;
  ref: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
};

const useModal: UseModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const open = useCallback((event: React.UIEvent) => {
    event.stopPropagation();
    setIsOpen(true);
  }, []);

  const close = useCallback((event: React.UIEvent) => {
    event.stopPropagation();
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: React.MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        close(event);
      }
    };

    const handleKeyOutside = (event: React.KeyboardEvent) => {
      if (event.code === "Tab" || event.code === "Enter") {
        close(event);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleKeyOutside);
      document.body.classList.add("noscroll");
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyOutside);
      document.body.classList.remove("noscroll");
    };
  }, [ref, isOpen, close]);

  const ModalWrapper: React.FC<ModalWrapperProps> = ({ children }) => {
    return createPortal(
      <div className="modal">
        <div className="modal-bg">{children}</div>
      </div>,
      document.getElementById("modal-container")!
    );
  };

  return {
    ModalWrapper,
    isOpen,
    ref,
    open,
    close
  };
};

export { useModal };
