import { useCallback, useRef } from "react";

import type React from "react";

export const useEventOnView = (
  event: (props: {
    ref: React.RefObject<HTMLElement>;
    entry: IntersectionObserverEntry;
    threshold: number;
  }) => void,
  { threshold = 0, runMoreThanOnce = false } = {}
) => {
  const hadAlreadyRan = useRef(false);

  const handleRef = useCallback(
    (node: HTMLElement | null) => {
      const elemHeight = node?.getBoundingClientRect().height || 0;
      const isElemBiggerThanScreen =
        elemHeight > window.innerHeight * threshold;
      const adjustedThreshold = isElemBiggerThanScreen
        ? ((window.innerHeight * threshold) / elemHeight) * threshold
        : threshold;
      if (typeof IntersectionObserver === "undefined") return;

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (hadAlreadyRan.current && !runMoreThanOnce) {
            observer.disconnect();
            return;
          }
          if (entry.isIntersecting) {
            event({ ref: { current: node }, entry, threshold });
            hadAlreadyRan.current = true;
          }
        },
        { threshold: adjustedThreshold }
      );
      if (node) observer.observe(node);
    },
    [threshold, event, runMoreThanOnce]
  );

  return { handleRef };
};
