import { useEffect, useState } from "react";

import { getTCF } from "../../components/Ad/utils/cookieConsent";

export function useConsentToAll() {
  const [consentToAll, setConsentToAll] = useState<boolean | null>(null);

  useEffect(() => {
    getTCF().then(tcf => {
      setConsentToAll(tcf.getConsentedToAllSync());
      tcf.isConsentedToAll(cto => {
        setConsentToAll(cto);
      });
    });
  }, [consentToAll]);
  return consentToAll;
}
