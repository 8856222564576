import { useState, useEffect, useContext, useCallback } from "react";

import { TrackingContext } from "../contexts/TrackingContext";
import { useGlobalStore } from "../containers/GlobalStateContainer/store";

type Panel = "profile" | "newsmix" | "main" | null;

type OpenPanel = (panel: Panel) => void;

type ClosePanel = () => void;

type UseMenu = () => {
  currentPanel: Panel;
  openPanel: OpenPanel;
  closePanel: ClosePanel;
};

const useMenu: UseMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentPanel, setCurrentPanel] = useState<Panel>(null);

  const { usermixOpened, usermixClosed } = useContext(TrackingContext);

  const { personalizedContent: { usermix } = { usermix: {} } } =
    useGlobalStore();

  useEffect(() => {
    const main = document.querySelector(".main");
    const feed = document.querySelector(".feed");

    if (currentPanel) {
      document.body.classList.add("noscroll-mobile");
      main?.classList.add("noscroll-mobile", "noscroll-mobile--feed");
      feed?.classList.add("noscroll-mobile", "noscroll-mobile--feed");
    } else {
      document.body.classList.remove("noscroll-mobile");
      main?.classList.remove("noscroll-mobile", "noscroll-mobile--feed");
      feed?.classList.remove("noscroll-mobile", "noscroll-mobile--feed");
    }
  }, [currentPanel, isOpen]);

  const openPanel: OpenPanel = useCallback(
    panel => {
      if (!panel || panel === currentPanel) {
        if (panel === "newsmix") {
          usermixClosed(usermix);
        }

        setIsOpen(false);
        setCurrentPanel(null);
      } else {
        if (panel === "newsmix") {
          usermixOpened(usermix);
        }

        setIsOpen(true);
        setCurrentPanel(panel);
      }
    },
    [currentPanel, usermix, usermixClosed, usermixOpened]
  );

  const closePanel: ClosePanel = useCallback(() => {
    setIsOpen(false);
    setCurrentPanel(null);
  }, []);

  return {
    currentPanel,
    openPanel,
    closePanel
  };
};

export { useMenu, type Panel, type OpenPanel, type ClosePanel };
