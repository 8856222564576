import { useCallback } from "react";
import { useRouter } from "next/router";

import { useGlobalStore } from "../containers/GlobalStateContainer/store";

export function useEnhancedRouter() {
  const router = useRouter();
  const { categories } = useGlobalStore();

  const getCategory = useCallback<() => string | undefined>(() => {
    if (router.pathname === "/category") {
      return categories.find(item => item.slug === router.query.slug)
        ?.category_id;
    }
    return;
  }, [categories, router.pathname, router.query.slug]);

  const getTopic = useCallback<() => string | undefined>(() => {
    if (router.pathname === "/topic" || router.pathname === "/topic_w_o_slug") {
      return router.query.topicId as string;
    }
    return;
  }, [router.query.topicId, router.pathname]);

  return {
    get category() {
      return getCategory();
    },
    get topic() {
      return getTopic();
    }
  };
}
