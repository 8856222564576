import { useEffect, useState } from "react";

import type { Article, Resource } from "../client";

import { getConfig } from "../config";

export function useHidePremiumContent(
  article: Article | (Resource & { is_premium?: boolean })
) {
  const [shouldHide, setShouldHide] = useState(true);
  const { NEXT_PUBLIC_MER } = getConfig();
  const { is_premium = false } = article;

  useEffect(() => {
    setShouldHide(is_premium && !NEXT_PUBLIC_MER);
  }, [is_premium, NEXT_PUBLIC_MER]);

  return shouldHide;
}
