import { useCallback } from "react";

import type { Topic } from "../client";

import { deleteTopicSubscription, updateTopicSubscription } from "../client";
import { showMessage } from "../helpers";
import { useGlobalStore } from "../containers/GlobalStateContainer/store";

const useSubcribedTopics = () => {
  const {
    isLoggedIn,
    personalizedContent: { subscribedTopics = [] } = {},
    setPersonalizedContent
  } = useGlobalStore();

  const addSubscription = useCallback(
    async (topic: Topic) => {
      if (!isLoggedIn) {
        showMessage({
          text: "Du måste logga in för att bevaka ämnen",
          id: "logInToSubscribe"
        });
        return;
      }
      const newSubscription = {
        topic_id: topic.topic_id,
        title: topic.title,
        push_level: 0,
        unread: 0,
        last_updated: "",
        type: topic?.type || ""
      };

      setPersonalizedContent({
        subscribedTopics: [...subscribedTopics, newSubscription]
      });

      showMessage({
        text: `Du bevakar nu ${topic.title}`,
        id: `nowSubscribing${topic.topic_id}`,
        hideAfter: 2500
      });

      try {
        const response = await updateTopicSubscription({
          topic_id: newSubscription.topic_id,
          body: { push_threshold: 0 }
        });

        console.info("UpdateTopicSubscription: ", response);
      } catch (error) {
        console.error(error);
      }
    },
    [isLoggedIn, setPersonalizedContent, subscribedTopics]
  );

  const removeSubscription = useCallback(
    async (topic: Topic) => {
      setPersonalizedContent({
        subscribedTopics: [
          ...subscribedTopics.filter(
            subscribedTopic => subscribedTopic.topic_id !== topic.topic_id
          )
        ]
      });

      try {
        const response = await deleteTopicSubscription({
          topicId: topic.topic_id
        });

        console.error("DeleteTopicSubscription ", response);
      } catch (e) {
        console.error(e);
      }
    },
    [setPersonalizedContent, subscribedTopics]
  );

  const isSubscribedToTopic = useCallback(
    (topic: Topic) =>
      !!subscribedTopics.find(
        subscribedTopic => subscribedTopic.topic_id === topic.topic_id
      ),
    [subscribedTopics]
  );

  return { addSubscription, removeSubscription, isSubscribedToTopic };
};

export { useSubcribedTopics };
