import { useEffect, useState } from "react";

import sizesRaw from "../config/shared-variables.json";

type Size = "xs" | "sm" | "lg" | "xl";

type Sizes = {
  [key in Size]: number;
};

export const SCREEN_MIN_SIZES: Sizes = Object.keys(sizesRaw).reduce(
  (prev, current) => {
    return {
      ...prev,
      [current.replace("screen-", "").replace("-min", "")]:
        sizesRaw[current as keyof typeof sizesRaw]
    };
  },
  { xs: 0 } as Sizes
);

const getSize = (width: number): Size => {
  let prev = Infinity;
  for (const [key, value] of Object.entries(SCREEN_MIN_SIZES).sort(
    (a, b) => b[1] - a[1]
  )) {
    if (width < prev && width >= value) return key as Size;
    prev = value;
  }
  return "xs";
};

export const useScreenSize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    width,
    height,
    size: getSize(width)
  };
};
