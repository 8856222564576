import React, { useCallback, useEffect, useState } from "react";

import type { MessageType, CustomEvent } from "../helpers";

export function Messages() {
  const [messages, setMessages] = useState([] as MessageType[]);

  const hideMessage = (id: string) => {
    setMessages(prev => prev.filter(message => message.id !== id));
  };
  const addMessage = useCallback(({ detail }: CustomEvent<MessageType>) => {
    if (detail.hideAfter) {
      setTimeout(() => {
        hideMessage(detail.id);
      }, detail.hideAfter);
    } else {
      detail.showCloseButton = true;
    }
    setMessages(prev => [
      ...prev.filter(prevMsg => prevMsg.id !== detail.id),
      detail
    ]);
  }, []);

  useEffect(() => {
    document.addEventListener("message", addMessage);

    return () => {
      document.removeEventListener("message", addMessage);
    };
  }, [addMessage]);

  return (
    <section className="messages">
      {messages.map(({ text, id, showCloseButton }) => {
        return (
          <div key={id} className="messages-message">
            {text}
            {showCloseButton && (
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
              <span
                tabIndex={0}
                role="button"
                aria-pressed="false"
                className="messages-message-close"
                onClick={() => hideMessage(id)}
              >
                ×
              </span>
            )}
          </div>
        );
      })}
    </section>
  );
}
